import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";

import { Header, Content } from "page_components/blog";

const NotFoundPage = ({ data }) => {
	return (
		<Layout>
			<Header />
			<Content
				data={data.allWpPost.nodes}
				categories={data.allWpCategory.nodes}
			/>
		</Layout>
	);
};

export const Head = () => <Seo title="Aktualności" />;

export const query = graphql`
	query {
		allWpPost(sort: { order: DESC, fields: date }) {
			nodes {
				id
				title
				uri
				slug
				date
				excerpt
				categories {
					nodes {
						slug
					}
				}
				featuredImage {
					node {
						localFile {
							publicURL
						}
					}
				}
			}
		}
		allWpCategory(sort: { order: ASC, fields: name }) {
			nodes {
				name
				slug
			}
		}
	}
`;

export default NotFoundPage;
